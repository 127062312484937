<template>
  <div :class={top:true,topBlock:isActive()}>
    <div class="container">
      <div class="left"><img :src="imageLogo" alt=""></div>
      <div class="nav-list d-flex f-16 align-items-center f-white">
        <div>
          <a :style="{color:$route.path!=='/' ? 'black' : 'white'}" href="/"
            class="active first-item homeSelected" @mouseover="show">首页</a>
        </div>

        <!-- <div>
          <router-link :style="{color:$route.path!=='/' ? 'black' : 'white','margin-left':'50px'}" to="/sbfl"
            class="active first-item homeSelected">商标分类</router-link>
        </div> -->
        
        <div class="dropdown-nav-item" v-for="(item,index) in filteredTopList" :key="index"
        @mouseenter="showDropdown(index)" @mouseleave="hideDropdown">
          <router-link :style="{color:$route.path!=='/' ? 'black' : 'white'}" :to="getRoutePath(item)" class="arrow-down color-inherit"
           >{{item.category_name}}</router-link>
          <ul class="f-black f-14 son" v-show="activeIndex===index">
            <li v-for="(productItem,productIndex) in item.productList.slice(0,5)" :key="productIndex">
              <router-link v-if="productItem.title!=='商标分类'" :to="{path:'/company_register',query:{id:productItem.id,category_id:productItem.category_id}}" class="">{{productItem.title}}</router-link>
              <router-link v-else to="/sbfl" class="">{{productItem.title}}</router-link>
            </li>
          </ul>
        </div>
   
        <div class="dropdown-nav-item">
          <a :style="{color:$route.path!=='/' ? 'black' : 'white'}" href="https://www.jzn.com/about.html"
            class="link-a" >关于我们</a>
          <!--        <div class="f-white">登录/免费注册</div>-->
          <!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: null,
        searchInput: '',
        topList: [],
        productList: []
      }
    },
    mounted() {
      this.getList()
      this.getProduct()
    },
    computed: {
      imageLogo() {
        switch (this.$route.path) {
          case '/':
            return require('@/assets/img/topLogo.png');
          default:
            return require('@/assets/img/topLogo-blue.png');
            break
        }
      },
      filteredTopList() {
        // return this.topList.map(item => ({
        //   ...item,
        //   productList: this.productList.filter(productItem => productItem.category_id === item.id).slice(0, 5)
        // }))
        return this.topList.map(item => {
      // 过滤出当前类别的产品
      let productList = this.productList.filter(productItem => productItem.category_id === item.id);

      // 只对 id 为 1 或 category_name 等于 "商标服务" 的类别进行特殊处理
      if ((item.id === 1 ) && productList.length < 5) {
        productList.push({
          // category_id: item.id,
          title: '商标分类', // 你可以根据需要设置其他属性
          url: '/sbfl' // 新的路由
        });
      } else if (item.id === 1 ) {
        // 如果 productList 的长度等于或大于 5，替换第五个位置的产品项
        productList[4] = {
          // category_id: item.id,
          title: '商标分类', // 你可以根据需要设置其他属性
          url: '/sbfl' // 新的路由
        };
      }

      return {
        ...item,
        productList: productList.slice(0, 5) // 确保 productList 只有 5 个元素
      };
    });
      }
    },
    methods: {
      show(){
       console.log(this.filteredTopList,'计算属性');
      },
      getRoutePath(item) {
      const path = item.id === 1 ? '/trademarks' : '/company_register';
      return {
        path: path,
        query: {
          id: item.productList[0].id,
          category_id: item.productList[0].category_id
        }
      };
    },
      showDropdown(index) {
        this.activeIndex = index;
      },
      hideDropdown() {
        this.activeIndex = null;
      },
      async getList() {
        const res = await this.$request.get('productcategories/getPaginate');
        if (res.data.code === 0) {
          this.topList = res.data.data.data
          this.topList.reverse()
        }
      },
      async getProduct() {
        const res = await this.$request.get('product/getAll');
        if (res.data.code === 0) {
          this.productList = res.data.data   
        }
      },
      isActive() {
        if (this.$route.path != '/') {
          return true
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    position: absolute;
    width: 100%;

    .container {
      height: 80px;
      display: flex;
      justify-content: space-around;

      .left {
        img {
          margin-top: 8px;
          height: 70px;
        }
      }

      .el-menu-demo {
        border-bottom: none;
        background-color: transparent;

        .el-menu-item {
          background-color: transparent;
          color: white;
        }

        .el-menu-item:hover {
          background-color: transparent;
        }

        .el-menu-item:active {
          background-color: transparent;
        }
      }

      .topMenu {
        color: black !important;
      }
    }
  }

  .topBlock {
    position: relative;
    background-color: white;
    border-bottom: 1px solid #ccc;
  }

  .dropdown-nav-item {
    position: relative;
    margin-left: 50px;
    height: 80px;
    white-space: nowrap;
  }
  .dropdown-nav-item>a{
    line-height: 80px;
  }

  .son {
    position: absolute;
    min-width: 130px;
    left: -34px;
    top: 80px;
    margin-bottom: 5px;
    padding-left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    background: white;
    z-index: 100;
    box-shadow: rgba(125, 125, 125, 0.3) 1px 5px 15px;
  }
  .son li{
    text-align: center;
  }
  .son a{
    max-width: 130px;
    color: #343a40;
    display: block;
    padding: 10px 26px 10px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.4;
    font-weight: 400;
    clear: both;
  }

  a {
    color: white;
  }
</style>