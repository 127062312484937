import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
   {
    path:'/',
    component: Home
   },
   {
    path:'/trademarks',
    name:'trademarks',
    component:()=>import('../views/trademarks')
   },
   {
    path:'/company_register',
    name:'company_register',
    component:()=>import('../views/company_register')
   },
   {
    path:'/authentication',
    name:'authentication',
    component:()=>import('../views/authentication')
   },
   {
    path:'/qualification-license',
    name:'qualification-license',
    component:()=>import('../views/qualification-license')
   },
   {
    path:'/sbfl',
    name:'sbfl',
    component:()=>import('../views/tradecategory')
   },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    return {x:0,y:0}
  }
})


export default router
