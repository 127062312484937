<template>
    <div id="footer">
        <div class="w_1180_f_height">
            <div id="f_logo">
                <a href="/">
                    <img style="margin: 0 0 10px 20px; width: 155px;"
                        src="../../assets/img/logo_jzn_footer.png">
                    </a>
                <span>AI财税+超企服平台</span>
            </div>
            <!--服务目录-->
            <dl v-for="(item,index) in filteredTopList" :key="index">
                <dt><router-link
                        :to="{path:'/company_register',query:{id:item.productList[0].id,category_id:item.productList[0].category_id}}">{{item.category_name}}</router-link>
                </dt>
                <dd v-for="(productItem,productIndex) in item.productList.slice(0,5)" :key="productIndex">
                    <router-link style="display: inline-block; width: 125px; ;
                    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
                        :to="{path:'/company_register',query:{id:productItem.id,category_id:productItem.category_id}}"
                        class="">{{productItem.title}}</router-link>
                </dd>
            </dl>
            <dl>
                <dt><a href="javascript:void(0)">关注机智鸟</a></dt>
                <div class="sideMenu2" style="margin:0 auto">
                    <h3 @click="toggleDisplay('menu1')">机智鸟公众号</h3>
                    <ul v-show="displayMenu === 'menu1'">
                        <li><img style="width: 120px;height: 120px;" src="../../assets/img/jzn_public.jpg"></li>
                    </ul>
                    <h3 @click="toggleDisplay('menu2')" class="on">机智鸟小程序</h3>
                    <ul v-show="displayMenu === 'menu2'">
                        <li><img style="width: 120px;height: 120px;" src="../../assets/img/jzn_code.jpg"></li>
                    </ul>
                    <h3 @click="toggleDisplay('menu3')">机智鸟视频号</h3>
                    <ul v-show="displayMenu === 'menu3'">
                        <li><img style="width: 120px;height: 120px;" src="../../assets/img/jzn_video.jpg"></li>
                    </ul>
                </div>
            </dl>
        </div>
        <div class="clearfix_footext1">
            <!--热门服务-->
            <div class="ul1" v-for="(item,index) in linkTopList" :key="index">
                <span>{{item.title}}</span>
                <ul v-for="(item2,index2) in item.linkList">
                    <li><a :href="item2.link">{{item2.content}} </a></li>
                </ul>
            </div>
        </div>
        <div class="w_1180_footext2">
            <a href="/about/index.html">关于我们</a>
            <a href="/about/certificate.html">公司资质</a>
            <a href="/about/join.html">加入小鸟</a>
            <a href="/about/contact.html">联系我们</a>
            <a href="/about/pay.html">支付方式</a>
            <a href="/license/agreement.html">服务协议</a>
            <a href="/license/privacyPolicy.html">隐私政策</a>
            <img src="../../assets/img/ico12.png">
            <span>400-800-4949</span>
        </div>
        <div class="w_1180_footext3">
            <p>©2015-2024 jzn.cn 版权所有</p>
            <p><a href="http://beian.miit.gov.cn" target="_blank">粤ICP备13087789号</a>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060402001306" target="_blank">
                    <img src="../../assets/img/ico13.png"> 粤公网安备 44060402001306号</a>
                <a href="http://wssq.saic.gov.cn:9080/tmsve/agentInfo_getAgentDljg.xhtml" target="_blank">
                    <img src="../../assets/img/ico14.png"> 国家知识产权局备案代理机构</a>
                <a href="http://wljg.gdgs.gov.cn/corpsrch.aspx?key=%E5%B9%BF%E4%B8%9C%E5%88%9B%E4%B8%9A%E5%B0%8F%E9%B8%9F%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8"
                    target="_blank">
                    <img src="../../assets/img/ico15.png"> 国家企业信用信息公示系统（广东）</a>
            </p>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                topList: [],
                displayMenu: 'menu2' ,
                productList: [],
                linkList: [],
                linkCategory:[]
            }
        },
        computed: {
            filteredTopList() {
                return this.topList.map(item => ({
                    ...item,
                    productList: this.productList.filter(productItem => productItem.category_id === item.id).slice(0, 5)
                }))
            },
            linkTopList(){
                return this.linkCategory.map(item=>({
                    ...item,
                    linkList: this.linkList.filter(linkitem=>linkitem.link_id===item.id)
                }))
            }
        },
        updated() {
        },
        mounted() {
            this.getProduct()
            this.getList()
            this.getLink()
            this.getLinkCategory()
        },
        methods: {
            async getProduct() {
                const res = await this.$request.get('product/getAll');
                if (res.data.code === 0) {
                    this.productList = res.data.data
                }
            },
            async getList() {
                const res = await this.$request.get('productcategories/getPaginate');
                if (res.data.code === 0) {
                    this.topList = res.data.data.data
                    this.topList.reverse()
                }
            },
            async getLink() {
                const res = await this.$request.post('link/getAll');
                if (res.data.code === 0) {
                    this.linkList = res.data.data
                    // const groupedData = this.linkList.reduce((acc, item) => {
                    //     console.log(item,'zi');
                    //     // 查找当前标题是否已经存在于累加器数组中
                    //     const existingGroup = acc.find(group => group.title === item.title);
                          
                    //     if (existingGroup) {
                    //         // 如果存在，将当前项添加到该组的items数组中
                    //         existingGroup.items.push(item);
                    //     } else {
                    //         // 如果不存在，创建一个新组，并将当前项作为第一个成员添加进去
                    //         acc.push({ title: item.title, items: [item||[]] });
                    //     }

                    //     return acc;
                    // }, []);

                    // console.log(groupedData);
                }
            },
            async getLinkCategory(){
                const res = await this.$request.post('linkcategory/getAll');
                if (res.data.code===0) {
                    this.linkCategory=res.data.data
                }
            },
            toggleDisplay(menuId) {
            this.displayMenu = menuId;
        }
        },

    }
</script>
<style lang="scss" scoped>
    #footer {
        width: 100%;
        background: #1175ff;
        padding: 30px 0 20px 0;
    }

    .w_1180_f_height {
        width: 1180px;
        height: 366px;
        position: relative;
        margin: 0 auto;
        border-bottom: 1px solid #005fea;
    }

    .w_1180_f_height::before {
        position: absolute;
        top: 30px;
        left: 305px;
        background: #005fea;
        width: 1px;
        height: 250px;
        content: ""
    }

    .w_1180_f_height #f_logo {
        width: 320px;
        height: 274px;
        float: left;
    }

    .w_1180_f_height #f_logo span {
        line-height: 24px;
        color: white;
        width: 254px;
        display: block;
        text-indent: 26px;
        font-size: 18px;
    }

    .w_1180_f_height dl {
        width: 130px;
        float: left;
        text-align: center;
    }

    .w_1180_f_height dl a {
        font-size: 14px;
        color: white;
        padding: 6px;
        text-decoration: none;
    }

    .w_1180_f_height dt {
        line-height: 40px;
    }

    .w_1180_f_height dl dd a:hover {
        transition: all .2s;
        background-color: #fed106;
        color: #005fea;
    }

    .w_1180_f_height dd {
        line-height: 25px;
    }

    .w_1180_f_height dl .sideMenu2 {
        margin: 0 auto;
    }

    .w_1180_f_height dl .sideMenu2 h3 {
        font-weight: normal;
        color: white;
        font-size: 14px;
        line-height: 36px;
        cursor: pointer;
    }

    .w_1180_f_height dl .sideMenu2 ul li {
        list-style: none;
    }

    .clearfix_footext1 {
        width: 1180px;
        margin: 0 auto;
        border-bottom: 1px solid #005be6;
        padding: 15px 0;
        overflow: hidden;
    }

    .clearfix_footext1 .ul1 {
        width: 1180px;
        position: relative;
        padding-left: 130px;
        height: 35px;
        font-size: 13px;
        line-height: 35px;
    }

    .clearfix_footext1 .ul1 span {
        position: absolute;
        top: 0;
        left: 23px;
        color: #fed106;
    }

    .clearfix_footext1 .ul1 ul {
        list-style: none;
    }

    .clearfix_footext1 .ul1 li {
        width: 120px;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        float: left;
    }

    .clearfix_footext1 .ul1 li a {
        text-decoration: none;
        font-size: 14px;
        color: white;
    }

    .w_1180_footext2 {
        margin: 0 auto;
        width: 1180px;
        line-height: 92px;
        text-indent: 23px;
    }

    .w_1180_footext2 a {
        text-decoration: none;
        padding-right: 23px;
        font-size: 15px;
        color: white;
    }

    .w_1180_footext2 img {
        width: auto !important;
        position: relative;
        top: -3px;
        margin-left: 40px;
        vertical-align: middle;
    }

    .w_1180_footext2 span {
        color: white;
        margin-left: 10px;
    }

    .w_1180_footext3 {
        width: 1180px;
        margin: 0 auto;
    }

    .w_1180_footext3>p,
    .w_1180_footext3>p>a {
        text-decoration: none;
        text-indent: 23px;
        line-height: 23px;
        font-size: 14px;
        color: white;
    }

    .w_1180_footext3>p a img {
        width: auto !important;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .service-container2>.item {
        margin-right: 5px;
    }
</style>